/*
 * @Author: 李聪
 * @Date: 2021-08-20 14:32:19
 * @LastEditors: 李聪
 * @LastEditTime: 2021-10-28 14:13:03
 * @Description:全局函数
 */

/**
 * @description: 获取页面传递的参数
 */
export const getParams = (): Record<string, string> => {
  const params: Record<string, string> = {};
  decodeURI(window.location.href.split('?')[1])
    .split('&')
    .forEach(item => {
      params[item.split('=')[0]] = item.split('=')[1];
    });
  return params;
};

/**
 * @description: 判断元素是否进入可视区域
 * @param {HTMLElement} el 需要判断是否进入可视区域的元素
 */
export const isElementVisible = (el: HTMLElement): boolean => {
  let isView = false;
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;
  const efp = function (x: number, y: number) {
    return document.elementFromPoint(x, y);
  };

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  ) {
    return isView;
  }

  // Return true if any of its four corners are visible
  isView =
    el.contains(document.elementFromPoint(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom));

  return isView;
};

/**
 * @description: 显示隐藏dom
 */
export const setOpacity = (
  btnIds: (HTMLElement | null)[] | HTMLElement | null,
  isShow = true
): void => {
  if (!Array.isArray(btnIds)) {
    if (btnIds) btnIds.style.opacity = isShow ? '1' : '0';
    return;
  }
  btnIds.forEach((item: HTMLElement | null) => {
    if (item) item.style.opacity = isShow ? '1' : '0';
    return;
  });
};
