import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fd4a5210")
const _hoisted_1 = {
  key: 0,
  class: "about-nav"
}
const _hoisted_2 = ["href"]
_popScopeId()

import { ref } from "vue";
import { getConfig, IHeaderNav } from "@/configs/getHeaderConfig";

export default _defineComponent({
  setup(__props) {

let headerConfig = ref<IHeaderNav[] | []>([]);

/**
 * @description: 获取配置列表
 */
const getHeaderConfig = async () => {
  const res = await getConfig();
  headerConfig.value = res;
};

getHeaderConfig();

return (_ctx: any,_cache: any) => {
  return (_unref(headerConfig).length)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(headerConfig)[5].children, (category) => {
          return (_openBlock(), _createElementBlock("li", {
            key: category.id,
            style: _normalizeStyle({
        width: category.marginRight && 60 + category.marginRight + 'px',
      }),
            class: "nav-module"
          }, [
            _createElementVNode("a", {
              href: `about.html?name=${category.text}&id=${category.id}#${category.maodian}`
            }, [
              _createElementVNode("span", null, _toDisplayString(category.text), 1)
            ], 8, _hoisted_2)
          ], 4))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})