import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-549e16b9")
const _hoisted_1 = {
  key: 0,
  class: "product-nav"
}
const _hoisted_2 = { class: "product-content" }
const _hoisted_3 = ["href"]
_popScopeId()

import { ref } from "vue";
import { getConfig, IHeaderNav } from '@/configs/getHeaderConfig';

export default _defineComponent({
  setup(__props) {

let headerConfig = ref<IHeaderNav[] | []>([])

/**
 * @description: 获取配置列表
 */
const getHeaderConfig = async () => {
  const res = await getConfig();
  headerConfig.value = res
}

getHeaderConfig();


return (_ctx: any,_cache: any) => {
  return (_unref(headerConfig).length)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(headerConfig)[1].children, (category) => {
          return (_openBlock(), _createElementBlock("li", {
            key: category.id,
            style: _normalizeStyle({ width: category.marginRight && 60 + category.marginRight + 'px' }),
            class: "nav-module"
          }, [
            _createElementVNode("span", null, _toDisplayString(category.text), 1),
            _createElementVNode("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.children, (product) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass([product.name, "product-content-li"]),
                  key: product.id
                }, [
                  _createElementVNode("a", {
                    href: `productdetail.html?category=${category.id}&id=${product.id}`
                  }, _toDisplayString(product.text), 9, _hoisted_3)
                ], 2))
              }), 128))
            ])
          ], 4))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})