import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container" }

import { onMounted,ref } from 'vue';
import SystemHeader from '@c/SystemHeader.vue';
import SystemFooter from '@c/SystemFooter.vue';
import PageTools from '@/components/system/PageTools.vue';


export default _defineComponent({
  props: {
    navActive: { type: Number, required: true }
  } as unknown as undefined,
  setup(__props: {
  navActive: number;
}) {


const medioClass = ref(true);
onMounted((): void => {
  medioClass.value = window.screen.width > 600 ? true : false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SystemHeader, { navActive: __props.navActive }, null, 8, ["navActive"]),
    _renderSlot(_ctx.$slots, "exhibition"),
    _createElementVNode("div", {
      class: _normalizeClass(["page-body", (__props.navActive===1)?'page-body2':'']),
      style: _normalizeStyle((__props.navActive===1)?'':(!medioClass.value?'width: 97%;':'width: 1200px;'))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6),
    ([2, 3, 4].includes(__props.navActive))
      ? (_openBlock(), _createBlock(PageTools, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(SystemFooter, { navActive: __props.navActive }, null, 8, ["navActive"])
  ]))
}
}

})