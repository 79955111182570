/*
 * @Author: 李聪
 * @Date: 2021-08-16 09:34:52
 * @LastEditors: 李聪
 * @LastEditTime: 2021-10-26 16:49:41
 * @Description:系统头部配置
 */

export interface IHeaderNav {
  id: number;
  name: string;
  text: string;
  children: IProductType[];
}

export interface IProductType {
  id: number;
  name: string;
  text: string;
  marginRight: number;
  children: IProduct[];
}
export interface IProduct {
  id: number;
  name: string;
  text: string;
  img: string;
  imgName: string;
  detail: {
    title: string;
    intro: string;
    sysChart?: string;
    tableData?: {
      value: string;
      label?: string;
      label2?: string;
      value2?: string;
      labelWidth?: number;
      valWidth?: number;
      labelColSpan?: number;
      valueColSpan?: number;
      rowSpan?: number;
      style?: Record<string, string>;
    }[][];
    bigImgs: string[];
    thumbs: string[];
  };
}

export async function getConfig(): Promise<IHeaderNav[]> {
  const res = await fetch('json/systemConfig.json');
  return res.json()
}
