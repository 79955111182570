/*
 * @Author: 李聪
 * @Date: 2021-08-23 17:49:13
 * @LastEditors: 李聪
 * @LastEditTime: 2022-01-21 09:23:28
 * @Description: 案例列表
 */

const caseList = [
  {
    id: 4,
    title: '汕头市内涝监测预警服务体系构筑“智慧一张图”',
    intro: '智能化监测预警平台，构建“智慧一张图”提升汕头城市内涝监测预警能力',
    url: 'https://mp.weixin.qq.com/s/0R41NbC46qO8tUHc2_DSog',
    cover: 'case/1.汕头市内涝监测预警服务体系构筑“智慧一张图”.png',
    content: `汕头市内涝监测预警服务体系是畅视科技以“物联网”理念为核心，针对汕头市易涝点进行内涝险情监测。加强汕头市城市内涝的预报预警以及信息发布，为救灾决策和快速反应措施制定提供决策支持，提升汕头市防灾减灾救灾能力，为汕头经济转型升级、社会和谐环境建设、营商环境建设保驾护航。`
  },
  {
    id: 5,
    title: '突发事件预警发布再创典范，打造“安全黄埔”新名片',
    intro: '抢占先机，赢得主动，预警信息“一键式”发布',
    cover: 'case/2.突发事件预警发布再创典范，打造“安全黄埔”新名片.png',
    url: 'https://mp.weixin.qq.com/s/w5MHXaV1anIJd3JezEMxJg',
    content: `黄埔区突发事件预警信息发布平台由中讯合创助力黄埔区气象局成功打造，作为黄埔区气象“十三五”规划重点建设内容，依托广东省、广州市、黄埔区气象局现有业务系统，扩建其信息接收、传输和发布手段及与之配套的系统，整合社会各种预警信息发布资源，建立起广覆盖、高时效的黄埔区突发事件预警信息发布体系，实现预警信息的及时制作、高效管理、快速发布。`
  },
  {
    id: 1,
    title: '新技术、新媒体赋能公共气象服务',
    intro: '',
    cover: 'case/6.新技术、新媒体赋能公共气象服务.png',
    url: 'https://mp.weixin.qq.com/s/y0rsyI4wuglnnyPql9NqZQ',
    content: ``
  },
  {
    id: 2,
    title: '正式启用!对标世界一流 携手启程信息化',
    intro: '',
    cover: 'case/1.正式启用.png',
    url: 'https://mp.weixin.qq.com/s/ioVHGZBLMUwYKYSPqaeLwA',
    content: ``
  },
  {
    id: 3,
    title: '“注入“智慧”新元素，气象科技为珠海提供硬核支撑',
    intro: '集强对流天气实时自动监测、识别、无缝隙格点化预报和预警等能力的短时临近预报系统',
    cover: 'case/5.注入“智慧”新元素，气象科技为珠海提供硬核支撑.png',
    url: 'https://mp.weixin.qq.com/s/8MdntwiHmr7ipFw5Z_Gd2w',
    content: ``
  },
  {
    id: 6,
    title: '硬核“五系统”缔造智慧预警，实现精准靶向发布',
    intro: '“智慧预警发布平台”全域智能，一键精准发布',
    cover: 'case/4.硬核“五系统”缔造智慧预警，实现精准靶向发布.png',
    url: 'https://mp.weixin.qq.com/s/3gHggFbGpkWfQ7OztMEtRw',
    content: ``
  },
  
];

export default caseList;
