/*
 * @Author: 李聪
 * @Date: 2021-08-20 17:18:28
 * @LastEditors: 李聪
 * @LastEditTime: 2021-10-28 09:26:18
 * @Description:企业动态
 */

export interface Company {
  id: number;
  title: string;
  intro: string;
  date?: string;
  url: string;
  cover: string;
  content: string;
}

const companyNews: Company[] = [
  {
    id: 36,
    title: '新技术、新媒体赋能公共气象服务',
    intro: '',
    date: '',
    cover: 'company/36.png',
    url: "https://mp.weixin.qq.com/s/y0rsyI4wuglnnyPql9NqZQ",
    content: ``
  },{
    id: 35,
    title: '正式启用!对标世界一流 携手启程信息化',
    intro: '世界气象中心（北京）粤港澳大湾区分中心正式启用！',
    date: '',
    cover: 'company/35.png',
    url: "https://mp.weixin.qq.com/s/ioVHGZBLMUwYKYSPqaeLwA",
    content: ``
  },{
    id: 34,
    title: '防灾减灾新格局 建设安全韧性社会',
    intro: '畅视科技助力打造有韧性的未来',
    date: '',
    cover: 'company/34.png',
    url: "https://mp.weixin.qq.com/s/X5_vF_0xdWKpv80_OQbGvg",
    content: ``
  },
  {
    id: 33,
    title: '推进网格化治理，打通“基层末梢”',
    intro: '社区网格化治理联防终端',
    date: '',
    cover: 'company/33.png',
    url: "https://mp.weixin.qq.com/s/V2oqJ-DEgeiNLEjQYfKqDQ",
    content: ``
  },
  {
    id: 32,
    title: '企业调研交流会，共谋发展助创新',
    intro: '省测控促进会、市仪器仪表学会莅临我司调研指导',
    date: '',
    cover: 'company/32.png',
    url: "https://mp.weixin.qq.com/s/Vi1EoQGKAD7q1FyfFqgKIA",
    content: ``
  },
  {
    id: 31,
    title: '智慧水站：迈上供水保障“智高点”',
    intro: '智慧水站/水厂信息化解决方案',
    date: '',
    cover: 'company/31.png',
    url: "https://mp.weixin.qq.com/s/qM6OFF_4L8IgOA8dCVwHrQ",
    content: ``
  },
  {
    id: 30,
    title: '国际气象节 | DIY制作气象数据',
    intro: '打造“高颜值”气象数据可视化看板',
    date: '',
    cover: 'company/30.png',
    url: "https://mp.weixin.qq.com/s/NPhG06BXlq6bX3rT7ZrRXA",
    content: ``
  },
  {
    id: 29,
    title: '畅视云桌面，高效远程调度',
    intro: '分布式云媒体管控系统之虚拟云桌面',
    date: '',
    cover: 'company/29.png',
    url: "https://mp.weixin.qq.com/s/wiu2fOCDYwnO3C9oWMFLzQ",
    content: ``
  },
  {
    id: 28,
    title: '筑牢防灾“第一道防线”，畅通安全“最后一公里”',
    intro: '国际减轻自然灾害日——多方协同、早期预警、应急响应行动',
    date: '',
    cover: 'company/28.png',
    url: "https://mp.weixin.qq.com/s/p-JiTST90PZouxQVq8tflg",
    content: ``
  },
  {
    id: 27,
    title: '全民追“圾”，一“图”掌握',
    intro: '世界清洁地球日，数智赋能垃圾分类',
    date: '',
    cover: 'company/27.png',
    url: "https://mp.weixin.qq.com/s/M4lhSpZupCo3bfdkDG218Q",
    content: ``
  },
  {
    id: 26,
    title: '六五环境日 | 护航气象“蓝图”，勾勒生态“画卷”',
    intro: '共建清洁美丽世界，加快推进气象高质量发展',
    date: '',
    cover: 'company/26.六五环境日.png',
    url: "https://mp.weixin.qq.com/s/24Xp64u0V97Kv-eqBMjXCQ",
    content: ``
  },
  {
    id: 25,
    title: '全国防灾减灾日 | 网格化治理筑牢防灾减灾“安全屏障',
    intro: '四大融合、敏捷发布、共建共享、联动协调',
    date: '',
    cover: 'company/25.全国防灾减灾日.png',
    url: "https://mp.weixin.qq.com/s/EWEQ8WQ_MT86NuXP4YPcwQ",
    content: ``
  },
  {
    id: 24,
    title: '世界地球日 | 污染防治，随“智”应变',
    intro: '健全生态环境质量监测网，深入打好污染防治攻坚战',
    date: '',
    cover: 'company/24.世界地球日污染防治，随“智”应变.png',
    url: "https://mp.weixin.qq.com/s/eiN6zzmkRoTMcohyR_K2eg",
    content: ``
  },
  {
    id: 23,
    title: '世界气象日 | 水文气象紧密耦合，筑牢防灾减灾防线',
    intro: '早预警，早行动——水文气象信息，助力防灾减灾',
    date: '',
    cover: 'company/23.世界气象日  水文气象紧密耦合，筑牢防灾减灾防线.png',
    url: "https://mp.weixin.qq.com/s/EQBSBLyyWTE503m6Yr0dTw",
    content: ``
  },{
    id: 22,
    title: '气象+智慧农业“ | 畅视领跑农业新气象',
    intro: '现代农业智慧气象服务平台让“体力活”转向“智力活”',
    date: '',
    cover: 'company/22.气象+智慧农业“  畅视领跑农业新气象.png',
    url: "https://mp.weixin.qq.com/s/LwwBQwrnhz1pNMuQTdc7Cg",
    content: ``
  },
  {
    id: 21,
    title: '全要素智能监测 一体化感知体系',
    intro: '一杆实现全要素、全天候、全覆盖、决策快、部署快！',
    date: '',
    cover: 'company/21.全要素智能监测 一体化感知体系.jpg',
    url: "https://mp.weixin.qq.com/s/_6ahEOYonYdjRZR01kTHHg",
    content: ``
  },
  {
    id: 11,
    title: '元宵节 | 万家灯火春日闹 元宵佳节护平安',
    intro: '',
    date: '',
    cover: 'company/11.元宵节 万家灯火春日闹 元宵佳节护平安.png',
    url: 'https://mp.weixin.qq.com/s/wNSWFvT1B1qmOnweyWGxQA',
    content: ``
  },
  {
    id: 12,
    title: '开工大吉',
    intro: '',
    date: '',
    cover: 'company/12.开工大吉.png',
    url: 'https://mp.weixin.qq.com/s/fmCyfElMh1hKgZ1zKqiNFw',
    content: ``
  },
  {
    id: 13,
    title: '贺新年 | 虎年大吉',
    intro: '',
    date: '',
    cover: 'company/13.贺新年虎年大吉.png',
    url: 'https://mp.weixin.qq.com/s/n9Ua41RC32mArb0vrd7e6Q',
    content: ``
  },
  {
    id: 14,
    title: '元旦节 | 元月元日元旦至，圆梦圆心圆万事',
    intro: '',
    date: '',
    cover: 'company/14.元旦节 元月元日元旦至，圆梦圆心圆完事.png',
    url: "https://mp.weixin.qq.com/s/6340GjX17kMC_1zoX1Xr2w",
    content: ``
  },
  {
    id: 15,
    title: '冬至已至，共度团圆',
    intro: '',
    date: '',
    cover: 'company/15.冬至已至，共度团圆.png',
    url: "https://mp.weixin.qq.com/s/_zUMi7p51d1ZFJ31c3_C-Q",
    content: ``
  },
  {
    id: 16,
    title: '智慧水务。“智”造升级',
    intro: '智慧水务解决方案以提升水资源效能为抓手，全面提升水务管理效率和效益。',
    date: '',
    cover: 'company/16.智慧水务智造升级.png',
    url: "https://mp.weixin.qq.com/s/kxOQHI0AzMo1Hl0VsNdndw",
    content: ``
  },
  {
    id: 17,
    title: '感恩节 | 为爱护航 感恩有你',
    intro: '科技惠及美好生活。',
    date: '',
    cover: 'company/17.感恩节为爱护航感恩有你.jpg',
    url: "https://mp.weixin.qq.com/s/wlFNsrKtcjPjREY5H-YoHw",
    content: ``
  },
  {
    id: 18,
    title: '智慧应急平台融通“智”与“治”',
    intro: '统筹多部门信息资源，为应急决策指挥赋能。',
    date: '',
    cover: 'company/18.智慧应急平台融通“智”与“治”.png',
    url: "https://mp.weixin.qq.com/s/qStNoEYaPyDIsPuXzIGkAg",
    content: ``
  },
  {
    id: 19,
    title: '数据可视化系统之“11.11”体验不打折',
    intro: '解密数据可视化系统背后的“高体验”核心技术。',
    date: '',
    cover: 'company/19.数据可视化系统11.11体验不打折.png',
    url: "https://mp.weixin.qq.com/s/puX5VMSZ9rsQj-a4zgoNyQ",
    content: ``
  },
  {
    id: 20,
    title: '消防安全日 | 火焰蓝与科技黑不畏烈火，只为平安',
    intro: '“蓝”朋友携“黑科技”平安守护你我他',
    date: '',
    cover: 'company/20.消防安全日 火焰蓝与科技黑不畏烈火，只为平安.png',
    url: "https://mp.weixin.qq.com/s/A0jCRmTYjtMON98uWThRSw",
    content: ``
  },
  
  {
    id: 1,
    title: '喜迎国庆 | 盛世华诞，锦绣中国',
    intro: '热烈庆祝中华人民共和国成立72周年！',
    date: '',
    cover: 'company/1.喜迎国庆  盛世华诞，锦绣中国.png',
    url: 'https://mp.weixin.qq.com/s/D_9RAazN2Kd3G46k4u-2sA',
    content: ``
  },
  {
    id: 2,
    title: '中秋佳节 | 圆月洒清辉，皎皎含真情',
    intro: '畅视科技祝愿团圆享天伦，幸福乐逍遥！',
    date: '',
    cover: 'company/2.中秋佳节圆月洒清辉，皎皎含真情.png',
    url: 'https://mp.weixin.qq.com/s/CZrMmGtVWFD6R2f_PZI4uw',
    content: `遥寄祝福千万缕，化作清风入梦里！
    畅视科技祝您中秋快乐！合家团圆！`
  },
  {
    id: 3,
    title: '去中心化分布式视音频系统',
    intro: '分布式视音频融合调度解决方案',
    date: '2021-08-09',
    cover: 'company/3.去中心化分布式视音频系统.png',
    url: 'https://mp.weixin.qq.com/s/VeJ0vuns68r2DRqdIXNBtQ',
    content: `分布式视音频系统是基于IP网络的视音频信号数字化融合传输、融合处理、融合调度及智能管控等功能于一体的新一代多媒体信息系统，解决传统集中式视音频传输距离短、信源难于扩展等痛点，轻松实现多个区域高质量视音频信号快速互联互通、设备自由控制，海量信号集约共享、敏捷调度及业务协同等能力`
  },
  {
    id: 4,
    title: '奥运再会 | 砥砺前行，永不止步',
    intro: '超越无止境，追逐无尽头',
    date: '2021-08-09',
    cover: 'company/4.奥运再会砥砺前行，永不止步.png',
    url: 'https://mp.weixin.qq.com/s/hMsygeDDHr9kNdBgBdtqcw',
    content: `步履阔步前行，收获夺银摘金，
    畅视祝愿祖国，盛世再创辉煌`
  },
  {
    id: 5,
    title: '全要素城市内涝一体化智能监测预警筑牢防灾减灾第一道防线',
    intro: '全要素城市内涝一体化智能监测预警助力海绵城市、韧性城市的建设',
    date: '2021-08-09',
    url: 'https://mp.weixin.qq.com/s/jASjJIcrrrSaHO477ZQYLg',
    cover: 'company/5.全要素城市内涝一体化智能监测预警筑牢防灾减灾第一道防线.png',
    content: `暴雨说来就来，街坊要小心“水浸街”。气象专家提醒，近期广州雷雨频繁，公众需留意最新预警预报信息，外出注意携带雨具。同时，气温较高，还需注意做好防暑降温工作。`
  },
  {
    id: 6,
    title: '建党节 | 愿祖国繁荣富强，人民安康！',
    intro: '七一到，祝福党青春永葆，领导中国率先跑！',
    date: '2021-08-09',
    cover: 'company/6.建党节愿祖国繁荣富强，人民安康.png',
    url: 'https://mp.weixin.qq.com/s/tLh5BcI_S-yIzzl4iRoSQQ',
    content: `热烈庆祝中国共产党成立100周年！`
  },
  {
    id: 7,
    title: '端午节 | 云“畅”粽子，安康过节',
    intro: '畅视科技邀您品尝“科技粽”',
    date: '',
    cover: 'company/7.端午节云“畅”粽子，安康过节.png',
    url: 'https://mp.weixin.qq.com/s/MxH7a5iOST-u7V8EQvvdFw',
    content: `防疫不误过端午，畅视邀您云吃粽！
    甜粽，肉粽，不如“科技粽”！今日带你品尝不一样的专属“科技粽”！`
  },
  {
    id: 8,
    title: '全要素交通桥梁一体化智能监测预警解决方案',
    intro: '桥梁长期健康监测',
    date: '2021-08-09',
    cover: 'company/8.全要素交通桥梁一体化智能监测预警解决方案.png',
    url: 'https://mp.weixin.qq.com/s/aUkG2AB9vZIaQLj9PaWrWw',
    content: `我国作为世界第一桥梁大国，大型城市桥梁约10万座，铁路桥梁约7万座，公路桥梁超过80万座。在桥梁的寿命周期中，养护期占桥梁寿命的90%，桥梁的长期正常使用，不仅取决于建造质量，更依赖于后期管养水平。桥梁长期健康监测是评估桥梁健康状态，保障桥梁安全，避免事故发生不可缺少的措施。`
  },
  {
    id: 9,
    title: '全要素水利一体化智能监测解决方案',
    intro: '“全要素、全天候、全覆盖、决策快、部署快”',
    date: '',
    cover: 'company/9.全要素水利一体化智能监测解决方案.png',
    url: 'https://mp.weixin.qq.com/s/jbHZ3Tl9-IyhHl8keQXTeQ',
    content: `我国江河流域、湖库众多，水利工程数量巨大，水资源、水生态、水安全的治理及监管面临严峻的挑战，传统的监测手段及“人海战术”的监管模式已经难以适应现代水利发展新格局。`
  },
  {
    id: 10,
    title: '全要素地灾一体化智能监测预警解决方案',
    intro: '利用现代化先进科技手段，加强各类地灾监测及预警',
    date: '',
    cover: 'company/10.全要素地灾一体化智能监测预警解决方案.png',
    url: 'https://mp.weixin.qq.com/s/6OcxgK6I9lqOMHrzQpm4MQ',
    content: `随着经济高速发展和人口过速增长，人类对自然资源索取不断加重，对自然环境开发及破坏日益强烈，不合理的人类经济活动致使地灾孕育及滋生日趋加剧，如：地下水资源失衡、岩土构造应力状态变化诱发了地面沉降、地面塌陷、地裂缝、崩塌、滑坡、泥石流等地灾的发生。`
  }
];

export default companyNews;
