const styleCss = {
    img: 'max-height: 100%;width:975px;',
    txtConent: 'text-indent:2em;width:975px;font-size:16px;line-height:32px;letter-spacing:2px;margin-top:20px;',
    txtUl:'list-style-type:disc;font-size: 16px;line-height:32px;letter-spacing:2px;margin-top:20px;margin-left:2em;',
    title: 'width:975px;font-size:20px;line-height:32px;letter-spacing:2px;margin-top:20px;color:#0e3942;font-weight:bold;',
    txtPbold: 'width:975px;font-size:16px;line-height:32px;letter-spacing:2px;margin-top:20px;font-weight:bold;',
    txtP: 'width:975px;font-size:16px;line-height:32px;letter-spacing:2px;margin-top:20px;',
};

const programmeList = [
    {
        id: 1,
        title: '智慧气象',
        content: 'INTELLIGENT METEOROLOGY',
        cover: 'programme/1.png',
        url: "programmeDetail.html?name=智慧气象&id=1",
        html: `
        <img style="${styleCss.img}margin-top:40px;" src="${require('@a/img/programmeDetail/1_1.png')}">
        <p style="${styleCss.txtConent}">《全国气象现代化发展纲要》（2015-2030年）：应用云计算、大数据、移动互联、物联网等信息技术，基于标准、高效、统一的数据环境，建立天气预报、气候预测、综合观测、公共气象服务以及行政管理等智能化、集约化、标准化的气象业务应用和科学管理系统。以信息化为基础，满足不同用户需求，加快构建和发展智慧气象，实现观测智能、预报精准、服务高效、管理科学的气象现代化发展模式。</p>
        <p style="${styleCss.title}">方案简介：</p>
        <p style="${styleCss.txtConent}">现代的气象环境监测系统是气象监测业务体系的重要组成部分，是提升公共气象服务能力和提高气象预报预测准确率的重要基础。智慧气象解决方案通过实时监测生态环境中的气象环境因子，为人们实时了解环境的天气状况和生态舒适度指标等气象数据提供准确、系统的资料。</p>
        <p style="${styleCss.txtPbold}">（1）一体化物联网智能监测设备：</p>
        <p style="${styleCss.txtConent}">通过一体化物联网智能监测设备采集空气温湿度、风速、风向、PM2.5、PM10、大气压力、雨量、负氧离子浓度等多种因素，再将采集的监测数据及图像通过GPRS/4G/5G/NB-IoT/风云卫星/北斗等信号上传至环境监测云平台。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/1_2.png')}">
        <p style="${styleCss.txtPbold}">（2）一体化智能感知展示平台：</p>
        <p style="${styleCss.txtConent}">与气象雷达监测数据、灾害天气短时临近预报进行深度耦合，为气象灾害预报建立评估模型，实现提前1至3小时滚动预报。具有自动气象监测、实时数据显示、实时图像显示、智能远程管理、数据分析存储的功能。具备大喇叭广播、声光报警、远程预警信息直接文字转语音发布等预警能力。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/1_3.png')}">
        `
    },
    {
        id: 2,
        title: '智慧农业',
        content: 'INTELLIGENT AGRICULTURE',
        cover: 'programme/2.png',
        url: "programmeDetail.html?name=智慧农业&id=2",
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/2_1.png')}">
        <p style="${styleCss.txtConent}">《中共广东省委 广东省人民政府关于全面推进乡村振兴加快农业农村现代化的实施意见》（粤发〔2021〕9号）提出的：“实施数字乡村建设工程，加强乡村公共服务、社会治理等数字化智能化建设。完善农业气象综合监测网络，提升农业气象灾害防范能力”。</p>
        <p style="${styleCss.title}">方案简介：</p>
        <p style="${styleCss.txtConent}">畅视科技精心打造现代农业智慧气象服务平台，通过“农业气象一体化监测系统+农业气象数字驾驶舱”实现“一站式”智能管控，共同推进数字乡村与智慧农业建设。</p>
        <p style="${styleCss.txtPbold}">（1）农业气象一体化监测系统：</p>
        <p style="${styleCss.txtConent}">通过布设一体化农业小气候智能杆，并结合已有的气象监测数据和相关农业数据信息，构建集气象环境、农作物生长态势、土壤墒情等要素以及病虫害远程识别、实景监控于一体的精细化现代农业物联网，为智慧农业产业建设提供示范。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;margin-bottom:40px;" src="${require('@a/img/programmeDetail/2_2.png')}">
        <p style="${styleCss.txtConent}">通过LED屏对园区农业气象条件与环境监测要素进行实时展示：空气温湿度、土壤墒情、光照等多种监测数据。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;margin-bottom:40px;" src="${require('@a/img/programmeDetail/2_3.png')}">
        <p style="${styleCss.txtPbold}">（2）农业气象数字驾驶舱：</p>
        <p style="${styleCss.txtConent}">基于气象监测数据和一体化农业小气候智能杆数据，通过大数据技术，建立规范统一、安全高效、维护便捷的数据支撑环境，为农业智慧气象服务系统提供基础数据支撑。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;margin-bottom:40px;" src="${require('@a/img/programmeDetail/2_4.png')}">
        <p style="${styleCss.txtConent}">以“可视化一张图”的形式展示农业气象实况数据、未来天气趋势预报、作物生长态势观测、土壤墒情、病虫害预警、灌溉数据等信息，提供实时灾害风险预警，实现农业气象环境的精准监测，使农业管理“智慧化”。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/2_5.png')}">
        `
    },
    {
        id: 3,
        title: '智慧交通',
        content: 'INTELLIGENT TRANSPORTATION',
        cover: 'programme/3.png',
        url: "programmeDetail.html?name=智慧交通&id=3",
        // url:'https://mp.weixin.qq.com/s?__biz=MzAwMjk3ODQwOQ==&mid=2247483989&idx=1&sn=14dbdc9436e3db32e742c452f31a0d2f&scene=19#wechat_redirect'
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/5_1.png')}">
        <p style="${styleCss.txtConent}">我国作为世界第一桥梁大国，大型城市桥梁约10万座，铁路桥梁约7万座，公路桥梁超过80万座。在桥梁的寿命周期中，养护期占桥梁寿命的90%，桥梁的长期正常使用，不仅取决于建造质量，更依赖于后期管养水平。桥梁长期健康监测是评估桥梁健康状态，保障桥梁安全，避免事故发生不可缺少的措施。</p>
        <p style="${styleCss.txtConent}">如何满足不同交通桥梁安全监测需求，提高安全监测准确性、可靠性及时效性，防范交通桥梁的突发事件是桥梁行业的必要需求。</p>
        <p style="${styleCss.title}">需求分析：</p>
        <p style="${styleCss.txtP}">（1）气象灾害对桥梁状态影响非常大，且容易引发其他次级灾害，需实现各类气象灾害精细化精准预报预警，对各类灾害天气提前3天滚动预报预警，以做好应急准备，提高应急响应效能。</p>
        <p style="${styleCss.txtP}">（2）当桥梁发生倾斜、沉落和偏移时，无法第一时间通知管理人员安全隐患以及险情。</p>
        <p style="${styleCss.txtP}">（3）桥梁构造复杂，传统人为巡视监测耗费大量人力物力，而且实施可靠程度难以保证。</p>
        <p style="${styleCss.txtP}">（4）桥梁上车水马龙，每天有较大承载力， 非常有必要实时有效地监控交通桥梁安全状态。</p>
        <p style="${styleCss.title}">方案简介：</p>
        <p style="${styleCss.txtConent}">全要素交通桥梁一体化智能监测预警解决方案，正是利用北斗高精度定位技术及基于物联网的应力应变、气象、地基等多种监测技术相结合，面对气象灾害、行车荷载、混凝土、钢筋、斜拉索、墩台基础等内外因素对桥梁形变造成安全影响实现实时监测、就地安全评估及预警，为交通桥梁安全运营保驾护航。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/5_2.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（1）桥梁本体监测预警：</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/5_3.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（2）桥梁地基监测预警：</p>
        <p style="${styleCss.txtConent}">桥址地震监测，可选用数字型加速度传感器。</p>
        <p style="${styleCss.txtConent}">应选用三向加速度传感器，包括两个相互垂直的水平方向和一个竖向。</p>
        <p style="${styleCss.txtConent}">频响范围宜在0-100Hz，量程不宜低于±2 g。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/5_4.png')}">
        <p style="${styleCss.title}margin-top:60px;">系统软件：</p>
        <p style="${styleCss.txtConent}">支持监控三轴历史数据、电压历史数据、温度湿度历史数据，多维显示，综合监控。</p>
        <p style="${styleCss.txtConent}">监控平台支持海量数据，系统设计百万终端同时在线，智能算法，及时报警，防范于未然。</p>
        <p style="${styleCss.txtConent}">支持单个/批量远程设置终端参数，如上报频度，温湿度阈值、电压阈值等。</p>
        `
    },
    {
        id: 4,
        title: '智慧城区',
        content: 'INTELLIGENT CITY',
        cover: 'programme/4.png',
        url:"programmeDetail.html?name=智慧城市&id=4",
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/4_1.png')}">
        <p style="${styleCss.txtConent}margin-top:20px;">《中华人民共和国国民经济和社会发展第十四个五年规划和2035年远景目标纲要》：分级分类推进新型智慧城市建设，将物联网感知设施、通信系统等纳入公共基础设施统一规划建设，推进市政公用设施、建筑等物联网应用和智能化改造。完善城市信息模型平台和运行管理服务平台，构建城市数据资源体系，推进城市数据大脑建设。探索建设数字孪生城市。加快推进数字乡村建设，构建面向农业农村的综合信息服务体系，建立涉农信息普惠服务机制，推动乡村管理服务数字化。</p>
        <p style="${styleCss.title}">方案简介：</p>
        <p style="${styleCss.txtP}">依托大数据技术，梳理、整合十五部门信息资源，充分利用图像识别、人工智能等前沿技术，采用音视频最新解决方案，构建具备资源共享、数据智能、部门协同、一网统管等能力的智慧城区风险监控调度体系，为城区高效、安全、有序运行提供智慧化安全保障。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_2.png')}">
        <p style="${styleCss.txtP}margin-top:80px;font-weight:bold;">（1）智慧城区安全数据大脑</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_3.png')}">
        <p style="${styleCss.txtConent}">采用“大平台、小应用”的架构，汇集应急管理局、气象局、公安局、交通运输局、水利局、林业局、海洋与渔业局、自然资源局、旅游局、教育局、城管局、卫生健康局、农业农村局、民政局等15个部门的数据资源，构建智慧城区风险态势融合监控调度体系的数据大脑。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_4.png')}">
        <p style="${styleCss.txtP}margin-top:80px;font-weight:bold;">（2）智慧城区一张图</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_5.png')}">
        <p style="${styleCss.txtConent}">采用B/S模式，“大平台、小应用”的架构，基于标准化的数据资源池接口，以电子地图为载体，实现各部门信息资源分层叠加可视化、资源统计、态势感知、监测预警等。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_6.png')}">
        <p style="${styleCss.txtP}margin-top:80px;font-weight:bold;">（3）云媒体交互系统</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_7.png')}">
        <p style="${styleCss.txtConent}">根据实时数据进行预测、判断、决策、指挥是指挥调度工作中必不可少的一项。分布式可视化坐席管理系统采用全面可视化交互的方式，不仅支持大屏数据可视化，还支持信号源可视化、大屏状态可视化、场景预览可视化、环境控制可视化。同时，所有视频信号都可以实时预览，大屏状态实时回显，音频支持查看静音、音量大小等状态。所有操作基于图形化、触摸拖拽式操作，达到人机交互功能。采用多点触控，通过全触控的方式即可实现信号窗口放大、缩小、移动、关闭等功能，在控制的同时实现所见即所得。</p>
        <p style="${styleCss.txtP}margin-top:80px;font-weight:bold;">（4）音视频互联系统</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/4_8.png')}">
        <p style="${styleCss.txtConent}">采用媒体云技术以及分布式高品质音视频技术，搭建具备多部门协同、风险态势融合监控的智慧城区音视频指挥系统，为智慧城区风险态势感知总览、风险应急指挥提供高体验、安全可靠的支撑平台。</p>
      `
    },
    {
        id: 5,
        title: '智慧水利',
        content: 'INTELLIGENT WATER',
        cover: 'programme/5.png',
        url: "programmeDetail.html?name=智慧水利&id=5",
        // url:"https://mp.weixin.qq.com/s?__biz=MzAwMjk3ODQwOQ==&mid=2247485861&idx=1&sn=b5763d700ae6b1d444bbf6047287e29c&scene=19#wechat_redirect"
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/3_1.png')}">
        <p style="${styleCss.txtConent}margin-top:20px;">随着全球变化和经济社会的快速发展，随着水资源短缺和水环境污染等问题的日渐突出，极端水文事件频繁发生，严重威胁我国水安全、粮食安全及生态环境安全。发展智慧水利正成为推动水利现代化，快速提升水资源效能的强力抓手和必然选择。</p>
        <p style="${styleCss.txtConent}margin-top:20px;">《广东省水利发展“十四五”规划》以广东智慧水利工程为抓手，推进新型基础设施与水利设施相融合，全方位构建水利智慧监管体系。深入贯彻落实习近平总书记“节水优先、空间均衡、系统治理、两手发力”十六字新时代水利工作方针。</p>
        <p style="${styleCss.txtConent}margin-top:20px;">践行水利部李国英部长最新提出的“智慧水利是水利高质量发展的最显著标志”以及“需求牵引、应用至上、数字赋能、提升能力”的智慧水利建设总要求。以数字化、网络化、智能化为主线，以数字化场景、智慧化模拟、精准化决策为路径，全面推进算据、算法、算力建设，加快构建具有“预报、预警、预演、预案”（四预）功能的智慧水利体系。紧密结合本地数字政府总体规划，现代水利信息化建设需达到“防洪保安全、优质水资源、健康水生态、宜居水环境、先进水文化、周到水服务”的总体目标。</p>
        <p style="${styleCss.title}">问题分析：</p>
        <p style="${styleCss.txtP}">（1）顶层设计理念落后，缺乏战略思维及系统思维。各自为政，以项目为单位重复投入建设，造成系统烟囱林立，信息孤岛严重，缺乏系统思维。</p>
        <p style="${styleCss.txtP}">（2）水利基础设施短板突出，大量水利监管对象甚至没有监测设施和相应的持续性、有效性监测数据，全域感知网络体系极不健全，数据严重不足，难以满足未来智慧水利的要求。：</p>
        <p style="${styleCss.txtP}">（3）未有统一性、普适性、颠覆性的感知技术产品。江河湖库充斥着标准不一、编码各异、算法算力不同、功能单一的“万国牌”感知技术产品，数据时序不对应、标记不齐全，一数多源现象普遍，难于形成水利统一大数据。：</p>
        <p style="${styleCss.txtP}">（4）感知体系以实测数据为主，尚未与气象、应急、环保等部门建立高效的数据交互机制，特别未与气象部门预报技术深度耦合，尚无法建立适合水利防洪减灾科学预测技术体系从而无法建立及时、高效的预警体系。</p>
        <p style="${styleCss.txtP}">（5）江河湖库数字化水平不高、覆盖率不足，3D建模、数字孪生等新一代技术在水利行业尚未得到广泛应用，大中型水利工程安全保障、防洪调度等预演水平较低。</p>
        <p style="${styleCss.txtP}">（6）面对防洪抗旱、水利基础工程运行安全监管等问题上还停留在人工会商和主要凭经验判断阶段。尚未建立标准化、规范化、结构化、程序化、规模化、数字化的应急预案，预测、预警体系尚未建立，智能预案难于实现，这些因素严重制约智慧水利的实现。</p>
        <p style="${styleCss.txtP}">（7）部省市县多级水利部门信息化项目建设未统一统筹，各自独立建设，条块分割严重、数据归集困难、系统分散、融合共享水平不高，效能低下。</p>
        <p style="${styleCss.txtP}">（8）水利服务社会、基层及群众等能力没有得到有效体现，既有系统能力不足问题，也有缺乏丰富多彩且有效手段和方法问题。没有通过建立水利工程与受益群众的可查可视化利益相关关系，需要采用新一代信息技术建立服务渠道及宣传手段，全面提升公众水利意识、防洪抗旱等科普知识。</p>
        <p style="${styleCss.title}">方案简介：</p>
        <p style="${styleCss.txtConent}">打造一个“横向到边、纵向到底”，省-市-县-镇-村五级“共建、共享、共治”的一体化水利智慧平台，实现水要素全域感知、水信息融合共享、水风险智慧管控、水管理协同高效、水服务泛在周到，为全面实现防洪保安全、优质水资源、健康水生态、宜居水环境、先进水文化、周到水服务目标提供重要技术支撑。</p>
        <img style="${styleCss.img}height:auto;margin-top:80px;" src="${require('@a/img/programmeDetail/3_2.png')}">
        <p style="${styleCss.txtConent}text-align:center;color:#909090">【智慧水利工程的“五全”目标】</p>
        <img style="${styleCss.img}height:auto;margin-top:80px;" src="${require('@a/img/programmeDetail/3_3.png')}">
        <p style="${styleCss.txtConent}text-align:center;color:#909090">【智慧水利工程的“五化”目标】</p>
        <img style="${styleCss.img}height:auto;margin-top:80px;" src="${require('@a/img/programmeDetail/3_4.png')}">
        <p style="${styleCss.txtConent}text-align:center;color:#909090">【总体架构】</p>
        <p style="${styleCss.txtPbold}margin-top:60px;">（1）重构全域感知一张网：</p>
        <p style="${styleCss.txtConent}">物联网与云计算相融合形成智联网，直接采用智联网来完善重构水利全域感知一张网。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_5.png')}">
        <p style="${styleCss.txtConent}">构建先进的现代水文气象智能感知网，重点推出集约化、小型化、低成本化，集多网融合、人工智能识别、边缘运算、末梢决策为一体的普适型全要素监测设备产品，提供实时、精准、有效的水文气象监测数据。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_6.png')}">
        <p style="${styleCss.txtConent}">基于便携式、移动式的人工智能识别终端，结合涉水巡查执法软体应用，采用4G/5G网络构建巡查执法一张网。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_7.png')}">
        <p style="${styleCss.txtConent}">采用微波/5G网络构建无人机日常规划定时自动巡查一张网。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_8.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（2）构建水文气象一朵云：</p>
        <p style="${styleCss.txtConent}">基于“数字政府”政务云，梳理各部门、各业务的数据，构建水利大数据，，以业务体系为板块提供可视化、开放式数据管理，提升数据的使用率。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_9.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（3）重构精细化主要耦合风险模型：</p>
        <p style="${styleCss.txtConent}">充分利用气象短时临近预报、定量数值预报的滚动提前3小时降水预报耦合水文动力模型，为流域洪涝、城市积涝、水库防洪调度提前、精准预报提供支撑。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_10.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（4）打造江河流域数字孪生：</p>
        <p style="${styleCss.txtConent}">充分利用图形GPU并行运算以及BIN建模，结合3D GIS技术开发江河流域数字孪生体仿真引擎；</p>
        <p style="${styleCss.txtConent}">基于数据可视化技术，结合全域感知数据、模型运算评估预报、打造江河流域数字孪生体仿真平台，为涉水工程运行全生命周期提供场景仿真、预演能力。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_11.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（5）重构统一公众服务发布平台：</p>
        <p style="${styleCss.txtConent}">构建统一多渠道发布系统，实现对微博、微信、网站、户外屏、电视、移动设备端等渠道的信息发布，全面提升公众服务能力。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/3_12.png')}">
        `
    },
    {
        id: 11,
        title: '智慧水站',
        content: 'SMART WATER STATION',
        cover: 'programme/11.png',
        url: "programmeDetail.html?name=智慧气象&id=11",
        html: `
        <img style="${styleCss.img}margin-top:40px;" src="${require('@a/img/programmeDetail/11_1.png')}">
        <p style="${styleCss.txtConent}">水利部印发《全国“十四五”农村供水保障规划》中提到，采取改造、新建、联网、并网等措施，巩固拓展农村供水成果，不断提高信息化管理水平。</p>
        <p style="${styleCss.txtConent}">《广东省水利发展“十四五”规划》：合理安排、布局农村饮用水水源，加快推进已划定的“千吨万人”饮用水水源地规范化建设，着力开展饮用水水源地环境问题清理整治。</p>
        <p style="${styleCss.txtConent}">《“十四五”水安全保障规划》：根据饮用水水源管护范围，完善隔离防护和警示设施，强化水质动态监测，健全水源保护巡查和跨区域协同保护机制，加强管护范围内风险防控，及时开展排查整治。</p>
        <p style="${styleCss.title}">方案简介：</p>
        <p style="${styleCss.txtConent}">智慧水站信息化解决方案，基于大数据、可视化等信息化手段，借助在线智能监测设备，实时感知水站运行状态，并依托“智慧水站信息管理平台”一张图、移动管控系统等手段实现对水站的远程监测+控制、实时预警、智慧可视化监管。</p>
        <p style="${styleCss.txtConent}">涵盖从水源、水站/水厂、窖井、管网到末端的全过程一站式数字化管理，实现对水站水质、流量等信息进行实时监测，精准识别管网漏损点位，促进水站区域智能调节，提高水站数字化、信息化、智慧化升级。</p>
        <p style="${styleCss.txtPbold}">（1）全流程监测：</p>
        <ul style="${styleCss.txtUl}"><li>水源安全监测</li></ul>
        <p style="${styleCss.txtConent}">【数据采集】：水源水质（浊度、PH值、温度等等）;</p>
        <p style="${styleCss.txtConent}">【视频监控】：水源关键位置，实时视频图像全面监控;</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_2.png')}">
        <ul style="${styleCss.txtUl}"><li>水站/水厂监测</li></ul>
        <p style="${styleCss.txtConent}">【数据采集】：监控水站/水厂水质（PH值、浊度、温度、余氯等等）、流量要素;</p>
        <p style="${styleCss.txtConent}">【自动调控】：混凝池和消毒池的药量实时监测，药量提醒及报警，全自动远程控制药量；淀后自动排泥装置、反冲洗全自动控制;</p>
        <p style="${styleCss.txtConent}">【视频监控】：水厂/水处理关键位置，实时视频图像全方位监控;</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_3.png')}">
        <ul style="${styleCss.txtUl}"><li>窖井智能监测</li></ul>
        <p style="${styleCss.txtConent}">【数据采集】：节点流量、水压、区域管网漏损情况、泵/阀/闸开关状态、开度流量调整;</p>
        <p style="${styleCss.txtConent}">【供电系统】：太阳能/蓄电池，全天候作业，持续稳定供电;</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_4.png')}">
        <ul style="${styleCss.txtUl}"><li>管网漏损监测</li></ul>
        <p style="${styleCss.txtConent}">【数据采集】：节点流量、水压、管网漏损情况、泵/阀/闸开关状态;</p>
        <p style="${styleCss.txtConent}">【供电系统】：太阳能/蓄电池/市电;</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_5.png')}">
        <ul style="${styleCss.txtUl}"><li>末梢保障监测</li></ul>
        <p style="${styleCss.txtConent}">【数据采集】：节点流量、水压、水质(PH值、浊度、余氯等等）异常关闭阀门、管道漏损情况、泵/阀/闸开关状态;</p>
        <p style="${styleCss.txtConent}">【视频监控】：现场环境监控;</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_6.png')}">
        <p style="${styleCss.txtPbold}">（2）智慧水站管理平台一张图：</p>
        <p style="${styleCss.txtConent}">采用B/S架构，将现有各类业务管理平台进行整合，形成平台及数据的联动效果，对水站/水厂的数据进行统一“汇管用”，快速了解水源—水站/水厂—窖井—管网—末端全流程的实况。运用BIM建模、三维建模、3D可视化等技术，打造水站/水厂数字孪生，对园区内重要设备、运转流程等进行全景重构。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_7.png')}">
        <p style="${styleCss.txtPbold}">（3）智慧水站移动管控系统：</p>
        <p style="${styleCss.txtConent}">移动端展示实时监测数据、现场监控画面，提供核心设备控制按钮，更加适配用户操作场景。</p>
        <p style="${styleCss.txtConent}">操作人员可随时随地查看水源—水站/水厂—窖井—管网—末端全流程实时数据监测、现场监控画面、设备控制等内容详情。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/11_8.png')}">
        `
    },
    {
        id: 6,
        title: '智慧应急',
        content: 'INTELLIGENT EMERGENCY',
        cover: 'programme/6.png',
        url: "programmeDetail.html?name=智慧应急&id=6",
        // url:"https://mp.weixin.qq.com/s?__biz=MzAwMjk3ODQwOQ==&mid=2247485775&idx=1&sn=5aabb88ba026eb1d6da9f5022cbd5f96&scene=19#wechat_redirect"
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/6_1.png')}">
        <p style="${styleCss.txtConent}">《应急管理信息化发展战略规划框架（2018-2022年）》：通过电子政务外网与国家共享交换平合对接获取其他部委应急管理业务相关数据，分类接入气象、自然资源、水利等外部单位信息资源，不断扩大信息获取范围。通过互联网为社会公众提供灾情信息发布、政务服务等相关信息。</p>
        <p style="${styleCss.txtConent}">《广东省数字政府改革建设“十四五”规划》：全面深化“智慧应急”建设，聚焦融合指挥、应急通信、短临预警、全域感知、数据智能五大方向持续攻关。</p>
        <p style="${styleCss.txtConent}">《广东省自然灾害防治能力建设行动方案》：实施移动通信指挥平台建设项目。构建集视频指挥、监控调度、视频会商、现场图传、态势展现等功能于一体的移动通信指挥平台，实现跨网跨部门跨区域的随行指挥。</p>
        <p style="${styleCss.title}margin-top:40px;">现状分析：</p>
        <p style="${styleCss.txtP}">（1）新时代防灾应急管理能力的要求：以全局、整体的思路着力开展多部门资源的有机整合、深度融合和信息共享，整合原分散在多个部门的应急管理职能，满足新时代应急管理要求，确保各项工作无缝衔接。</p>
        <p style="${styleCss.txtP}">（2）部门联动作战协同能力急需提升：部门间业务系统可能采用不同空间数据库，地理坐标系、图示规范等数据标准不一，缺乏统一规划，数据难以统一更新和智能分析，信息孤岛严重；部门间业务条块分割，联动及作战协同效率低下。</p>
        <p style="${styleCss.txtP}">（3）应急管理能力急需延伸到基层：应急会商、融合调度系统建设、维护成本高。随着物联网、大数据等信息技术在现代应急管理体系不断应用，如何将触角向基层一线延伸，成为各级应急管理部门努力的方向。监测地点一般较偏远，现场环境恶劣，存在网络、供电等问题。</p>
        <p style="${styleCss.title}margin-top:40px;">方案简介：</p>
        <p style="${styleCss.txtConent}">依托大数据技术，梳理、整合十五部门信息资源，引入气象短时临近预报研究成果，采用音视频最新解决方案，构建具备资源共享、数据智能、短临预警、部门协同等能力的防灾减灾救灾指挥体系。</p>
        <p style="${styleCss.txtConent}">采用移动通讯、融媒体等最新技术，快速将防灾减灾救灾指挥能力延伸到乡镇最后一公里，打通“省、市、县、镇街、乡村”五级融合指挥调度。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/6_2.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（1）多源数据资源池：</p>
        <p style="${styleCss.txtConent}">采用“大平台、小应用”的架构，汇集应急管理局、气象局、公安局、交通运输局、水利局、林业局、海洋与渔业局、自然资源局、旅游局、教育局、城管局、卫生健康局、农业农村局、民政局等多个部门的数据资源，构建防减救指挥体系多源数据资源池。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/6_3.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（2）应急指挥一张图：</p>
        <p style="${styleCss.txtConent}">应急指挥一张图可总览全区域风险态势，全程追踪事件发展趋势，实时、直观监控调度执行状况，全面提升应急指挥效能。</p>
        <p style="${styleCss.txtConent}">基于分析结果，提供一键启动应急处置功能，实现各模块互联互通；自动分析、统计受灾范围内所有应急物资和应急资源以及重点防护目标；智能推送应急预案，实现各单位敏捷联动处置；实时视频互联，指挥中心与灾害现场在线融合调度；向应急联动成员及单位快速靶向发布事件信息及下发相关任务指令，还可进行实时上报反馈，实现内外、上下在线协同。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/6_4.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（3）应急视频互联会商：</p>
        <p style="${styleCss.txtConent}">应急视频互联会商指挥平台采用媒体云技术，分布式高品质音视频技术，搭建分布式音视频互联会商以及信息集合展示系统，可实现多级融合，包括信号联动可视化管理、多源信号集中控制、多部门海量信号接入、大屏拼接、KVM坐席协作等，为防灾减灾救灾指挥体系提供高质量、高体验、安全可靠的可视化会商决策及融合指挥平台。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/6_5.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（4）基层融合指挥：</p>
        <p style="${styleCss.txtConent}">基层融合指挥快速解决五级融合指挥、信息发布、上下协同能力，做到上下贯通、左右衔接、互联互通、信息共享、互为支撑、安全畅通的省、市、县区、镇街、乡村五级融合调度体系，实现全域覆盖。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/6_6.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（5）应急指挥移动端：</p>
        <p style="${styleCss.txtConent}">智慧应急融合指挥移动端，随时随地进行应急决策协同指挥，提高应对突发事件快速反应能力和资源利用效率，实现指挥智慧化 、一体化 、扁平化 、多元化。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/6_7.png')}">
        `
    },
    {
        id: 7,
        title: '智慧医院',
        content: 'INTELLIGENT HOSPITAL',
        cover: 'programme/7.png',
        url: "programmeDetail.html?name=智慧医院&id=7",
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/7_1.png')}">
        <p style="${styleCss.txtConent}">智慧医院是综合应用物联网、数据融合传输交换、云计算等技术，通过信息技术将医院的物理空间和数字空间高度融合，利用数字化对物理空间进行重构与赋能，并在此基础上进行智能决策，实现精细化管理与精准化服务，让医院院区“智慧”起来。</p>
        <p style="${styleCss.txtConent}">近年来，我国智慧医疗市场的需求不断增长，市场规模迅速扩大，目前已成为仅次于美国和日本的世界第三大智慧医疗市场，预计未来十年复合增速将达到33.6%，智慧医院将是未来的发展方向。</p>
        <p style="${styleCss.txtConent}">2019年后，在疫情、政策以及技术等多个因素的推动下，医院对于大数据建设与应用等信息化系统越来越重视，且接受程度越来越高，并直接推动了中国乃至全球医疗健康产业数字革命，推动整个产业进入全新格局。</p>
        <p style="${styleCss.txtConent}">信息化、大数据、人工智能、云技术的发展迅速，对医疗卫生的服务模式、服务理念产生巨大的影响，传统医疗信息化迎来了转型升级时机，为满足医院信息化、人性化、智能化、低碳化的运营管理需求，提出了更高的要求。</p>
        <p style="${styleCss.title}margin-top:40px;">现状分析：</p>
        <p style="${styleCss.txtPbold}">（1）信息化程度低：</p>
        <p style="${styleCss.txtConent}">医院内部出现信息孤岛现象，医院内部部门多，各类平台、系统多且独立，无法做到数据的汇总和共享，无法统一进行管理。</p>
        <p style="${styleCss.txtPbold}">（2）资源管理不到位：</p>
        <p style="${styleCss.txtConent}">医院缺乏完善的资源统计工具，无法将数据进行统一采集，汇总、分析、存储，缺乏即时性、安全性和准确性，高耗低效，达不到管理智慧化。</p>
        <p style="${styleCss.title}margin-top:40px;">方案简介：</p>
        <p style="${styleCss.txtConent}">采用大数据，互联网等先进技术手段，建立完善先进的运维态势管理系统，做到运筹帷幄、合理决策，从而提高管理效率，降低管理成本，促进可持续发展，提升统筹管理能力，实现精细化管理与精准化服务。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/7_2.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（1）医院运维态势监测系统：</p>
        <p style="${styleCss.txtConent}">医院决策管理层或管理人员对医护人员信息、床位信息以及环境监测等总体运维态势情况掌握，全面提升智慧管理效能。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/7_3.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（2）医院智能调度系统：</p>
        <p style="${styleCss.txtConent}">采用分布式调度系统，实现集中掌控、应急指挥调度、远程视频会议、培训示教、以及对病人信息、相关系统信息等等进行调度。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/7_4.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（3）医院智能导览系统：</p>
        <p style="${styleCss.txtConent}">面向公众可视化展示当前科室信息、楼层科室定位导航、实时环境要素监测以及医院宣传信息等。可针对不同楼层、不同科室显示不同内容。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/7_5.png')}">
         `
    },
    {
        id: 10,
        title: '智慧养老院',
        content: 'INTELLIGENT NURSING HOME',
        cover: 'programme/10.png',
        url: "programmeDetail.html?name=智慧养老院&id=10",
        html: `
        <img style="${styleCss.img}margin-top:40px;" src="${require('@a/img/programmeDetail/10_1.png')}">
        <p style="${styleCss.txtConent}">随着中国人口老龄化的不断发展,智慧养老已成为智慧城市建设中的重要内容。《“十四五”国家老龄事业发展和养老服务体系规划》强调，利用大数据、人工智能等技术创新服务模式，提升健康监测产品、养老监护装置等适老产品的智能水平、实用性和安全性。</p>
        <p style="${styleCss.txtConent}">畅视科技智慧养老院解决方案立足于养老院现状，运用大数据、物联网等技术，对人员、物资等多方面安全需求进行监测、预警、提示、报警，使养老服务信息数据化，打造快捷、高效、安全的一体化智慧养老院。</p>
        <p style="${styleCss.title}">一、基础部分：</p>
        <p style="${styleCss.txtPbold}">（1）监测报警系统</p>
        <p style="${styleCss.txtConent}">①覆盖全院区室内楼宇监控，对人员活动进行跟踪，保障人员安全。</p>
        <p style="${styleCss.txtConent}">②对养老院室外活动区域，进行实时监控，远程巡视，全面加强安全防护。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_2.png')}">
        <p style="${styleCss.txtPbold}">（2）公共广播系统</p>
        <p style="${styleCss.txtConent}">播放紧急通知，实现人员疏导，可分区分时进行广播，广播内容可预定、点播播放音乐、新闻、通知、广告及文娱节目。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_3.png')}">
        <p style="${styleCss.txtPbold}">（3）信息发布系统</p>
        <p style="${styleCss.txtConent}">①形象宣传：以视频、动画、文字等形式组合制作播放企业文化形象；</p>
        <p style="${styleCss.txtConent}">②信息公告：发布通知、公告等信息，如子女探访提示、服务收费公示、服务规范公示、紧急通知等；</p>
        <p style="${styleCss.txtConent}">③娱乐休闲：在人员密集的场所，播放电视节目、新闻报道、娱乐短片、MTV、电影等；</p>
        <p style="${styleCss.txtConent}">④健康指引：院内区域介绍、实时天气播报、环境指数、健康指引等。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_4.png')}">
        <p style="${styleCss.title}">二、智慧照护：</p>
        <p style="${styleCss.txtPbold}">（1）智能穿戴</p>
        <p style="${styleCss.txtConent}">①卫星定位：实时、准确的定位老人位置及活动轨迹。</p>
        <p style="${styleCss.txtConent}">②健康检测：检测老人心率、血压、血糖、血氧等健康信息，数据上传到健康管理平台，形成健康数据档案。</p>
        <p style="${styleCss.txtConent}">③紧急呼叫：设备配有SOS报警功能按钮，当触发报警，系统以及显示平台、管理人员手机会显示报警人、报警位置、报警类型。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_5.png')}">
        <p style="${styleCss.txtPbold}">（2）智能控制系统</p>
        <p style="${styleCss.txtConent}">采用智能控制面板，提供简易式开关电视、窗帘、灯光、空调等，便利老人及管理人员。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_6.png')}">
        <p style="${styleCss.txtPbold}">（3）智能环境监测</p>
        <p style="${styleCss.txtConent}">监测室内外温度、湿度、光照强度、空气质量等各个方面的环境参数，并联动相应设备，进行告警与调节，确保养老院环境质量保持在健康水平。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_7.png')}">
        <p style="${styleCss.txtPbold}">（4）智慧巡防</p>
        <p style="${styleCss.txtConent}">巡逻人员利用巡检器进行巡查工作，建立巡防记录、应急处置电子台账。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_8.png')}">
        <p style="${styleCss.title}">三、智慧管理：</p>
        <p style="${styleCss.txtPbold}">（1）云媒体管控系统</p>
        <p style="${styleCss.txtConent}">采用数字媒体传输节点，整合多个区域视音频信号，实现互联互通，敏捷调度。如视频监控画面调取、业务平台查看，数据可视化展示等等。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_9.png')}">
        <p style="${styleCss.txtPbold}">（2）数据可视化平台</p>
        <p style="${styleCss.txtConent}">结合各系统监测数据，运用数据地图方法和可视化展示技术，全方位、多层次、多角度展示监测服务数据，满足养老院业务监控、风险预警、人员定位、地理信息分析等多种业务的展示需求。</p>
        <img style="${styleCss.img}margin-top:20px;" src="${require('@a/img/programmeDetail/10_10.png')}">
        <p style="${styleCss.txtConent}">畅视科技通过创新技术的应用与赋能提升老年人在信息化、智能化发展中的获得感、幸福感与安全感，提升老年人生活质量，推动养老行业数智化变革，推进智慧养老产业快速发展。</p>
        `
    },
    {
        id: 8,
        title: '地灾防治',
        content: 'DISASTER MANAGEMENT',
        cover: 'programme/8.png',
        url: "programmeDetail.html?name=地灾防治&id=8",
        // url:"https://mp.weixin.qq.com/s?__biz=MzAwMjk3ODQwOQ==&mid=2247483949&idx=1&sn=a0fb8ae73466bf2820f941ee8a540ac4&scene=19#wechat_redirect"
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_1.png')}">
        <p style="${styleCss.txtConent}">随着经济高速发展和人口过速增长，人类对自然资源索取不断加重，对自然环境开发及破坏日益强烈，不合理的人类经济活动致使地灾孕育及滋生日趋加剧，如：地下水资源失衡、岩土构造应力状态变化诱发了地面沉降、地面塌陷、地裂缝、崩塌、滑坡、泥石流等地灾的发生。</p>
        <p style="${styleCss.title}margin-top:40px;">需求分析：</p>
        <p style="${styleCss.txtP}">（1）通讯依托公共网络（物联网卡），边远地区信号差，存在大量盲区，数据无法及时、稳定上传；同时北斗通信建设成本高。</p>
        <p style="${styleCss.txtP}">（2）以往地灾监测信息需上传至云平台进行计算后再把运算结果回传，导致监测、预警不及时，由运管中心通知一线责任人，本地决策能力弱。</p>
        <p style="${styleCss.txtP}">（3）不同地灾监测场景采用不同的监测设备，难于做到同一设备兼容多种不同的监测场景，设备普适型较差，导致建设维护的成本较高。</p>
        <p style="${styleCss.txtP}">（4）强降水或持续性降水是引起地灾的主要诱因，现有地灾监测模式主要是实测，缺少与监测区域未来的降水预报相结合，难于较好实现地灾预报预警。</p>
        <p style="${styleCss.title}margin-top:40px;">方案简介：</p>
        <p style="${styleCss.txtConent}">本方案采用具备“多网融合、全面感知、敏捷运算、末梢决策、超高精度”等能力的地灾一体化监测智能杆为支撑设备；结合地理信息系统技术，以一定范围（区域）的地表位移、沉陷、崩塌、滑坡、泥水流等地质形变为监测对象，对灾变诱发因素进行实时、连续、动态的监测及就地运算研判，对可能发生的地灾预警通过多种通讯方式精准推送到一线监测或受影响的人员，快速构建一个面向各类地灾突发事件的智能监测预警体系。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_2.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（1）崩塌监测预警：</p>
        <p style="${styleCss.txtConent}">崩塌监测预警以北斗高精度定位，监测变形和降雨为主，对灾害隐患点现场位移、降雨量、裂缝、倾角、加速度等等要素进行全面监测，可增加大喇叭、声光报警仪、摄像头和次声波监测，可补充开展物理场监测（如应力应变等）。</p>
        <p style="${styleCss.txtConent}">使用GPRS/4G/5G/LoRa/NB-IoT/北斗等通信方式将解算结果、数据变化量、变化趋势等信息传输到云平台，按预警阈值或地灾模型进行预报预警，通过无线广播、短信、预警灯进行预警播报。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_3.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（2）滑坡监测预警：</p>
        <p style="${styleCss.txtConent}">滑坡监测预警以北斗高精度定位，监测变形和降雨为主，对灾害隐患点现场位移、降雨量、裂缝等要素进行全面监测，可增加大喇叭、声光报警仪、摄像头和次声波监测，可补充开展物理场监测（如应力应变等）。</p>
        <p style="${styleCss.txtConent}">使用GPRS/4G/5G/LoRa/NB-IoT/北斗等通信方式将解算结果、数据变化量、变化趋势等信息传输到云平台，按预警阈值或地灾模型进行预报预警，通过无线广播、短信、预警灯进行预警播报。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_4.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（3）泥石流监测预警：</p>
        <p style="${styleCss.txtConent}">泥石流监测以具体包括降雨量、泥位、含水率、倾角和加速度等监测项为主，增加大喇叭+声光报警仪、增加摄像头和次声波监测，可补充开展物理场监测（如应力应变等），将解算结果、数据变化量、变化趋势等信息传输到云平台，按预警阈值或地灾模型进行预报预警，采用广播、短信、预警灯等方式播报预警。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_5.png')}">
        <p style="${styleCss.txtPbold}margin-top:60px;">（4）沉降监测预警：</p>
        <p style="${styleCss.txtConent}">以北斗高精度定位结合地下水，降雨量等多要素监测为核心，对地表沉降隐患点的状态进行实时、动态监控，按预警阈值或地灾模型进行预报预警，采用广播、短信、预警灯等方式播报预警。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_6.png')}">
        <p style="${styleCss.title}margin-top:60px;">系统软件：</p>
        <p style="${styleCss.txtConent}">B/S结构，支持远程访问；兼容多种通信方式；支持图像、视频监控；组件组合、界面灵活制作；无缝对接其它平台软件。</p>
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/8_7.png')}">
        `
    },
    {
        id: 9,
        title: '内涝防治',
        content: 'WATERLOGGING MANAGEMENT',
        cover: 'programme/9.png',
        url: "programmeDetail.html?name=内涝防治&id=9",
        // url:"https://mp.weixin.qq.com/s?__biz=MzAwMjk3ODQwOQ==&mid=2247484655&idx=1&sn=dc0a8158e372b7b6bb6216cc5ff2d11f&scene=19#wechat_redirect"
        html: `
        <img style="${styleCss.img}height:auto;margin-top:40px;" src="${require('@a/img/programmeDetail/9_1.png')}">
        <p style="${styleCss.txtConent}">城市内涝是指由于强降水或连续性降水超过城市排水能力致使城市内产生积水灾害的现象。目前我国城市化发展迅速，随之而来的诸多效应中，有许多因素加剧了汛期街道积涝的情况。城市积水造成公用设施受损，使交通、电力、通讯、网络传输、水源等受到了严重影响或损坏，给人们生产生活带来诸多不便。另外，随着城市人口、资产密度提高，同等淹没情况下损失增加，且城市中枢作用使得次生影响和间接损失加大，积涝严重时可能造成重大的经济损失和人员伤亡。</p>
        <p style="${styleCss.title}margin-top:40px;">问题分析：</p>
        <p style="${styleCss.txtP}">（1）受到潮水顶托影响，遇到暴雨天，城市容易出现排水不便而导致积水，“逢雨必涝，遇涝则瘫”</p>
        <p style="${styleCss.txtP}">（2）部分城区的排水系统建设跟不上城市发展速度，排水管网不完善，老城区排水标准较低。</p>
        <p style="${styleCss.txtP}">（3）部分管渠河道淤积严重，排涝能力不足。在信号弱、无信号地区实现对一定范围区域内的滑坡、泥石流及崩塌等地质形变进行监测。</p>
        <p style="${styleCss.title}margin-top:40px;">方案简介：</p>
        <p style="${styleCss.txtConent}">全要素城市内涝一体化智能监测预警解决方案，是利用传感器技术、无线传输技术对易涝点的积水水位、降雨量进行实时监测、灾情实时监控，基于城市地形、排水系统结合气象暴雨预报建立积涝模型实现灾情事前研判及评估，通过声光图像等方式进行提前预警提醒。城市积涝监测预警是城市治理的重要组成部分，通过5G/4G/NB/北斗等通讯方式无缝对接智慧城市指挥中心。</p>
        <img style="${styleCss.img}height:auto;margin-top:60px;" src="${require('@a/img/programmeDetail/9_2.png')}">
        <p style="${styleCss.txtConent}">一方面，通过提高内涝点的监测力为市委市政府、三防、应急等相关部门提供实时的内涝险情信息，为防灾减灾提供决策信息。</p>
        <p style="${styleCss.txtConent}">另一方面，提高内涝预警的传播效率可以缩减政府各联动单位应对灾害反应时间、提高应对效率，将内涝预警信息更快地发送至目标用户有助于为防灾减灾争分夺秒。</p>
        <img style="${styleCss.img}height:auto;margin-top:60px;" src="${require('@a/img/programmeDetail/9_3.png')}">
        <p style="${styleCss.txtConent}">全要素智能监测单元与LED预警屏对接，部署于积水点前面（1米-1公里提前预警提醒），采用物联网Lora/NB通讯标准，无需在城市街道、高速路上挖掘布线。</p>
        <p style="${styleCss.title}margin-top:40px;">系统软件：</p>
        <p style="${styleCss.txtConent}">地理信息平台（GIS）；内涝监测信息点；内涝灾害监测数据；降水实况、水文实况；实时视频监控；定量降水估测等。</p>
        <img style="${styleCss.img}height:auto;margin-top:20px;" src="${require('@a/img/programmeDetail/9_4.png')}">
        `
    },
];

export default programmeList;