import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"
import _imports_0 from '@a/icons/close.png'


const _hoisted_1 = {
  key: 0,
  class: "doalog__wrapper"
}
const _hoisted_2 = { class: "dialog" }
const _hoisted_3 = { class: "dialog__body" }




export default _defineComponent({
  props: {
  isOpen: {
    type: Boolean,
    default: false
  }
},
  emits: ['close-modal'],
  setup(__props, { emit: emits }) {




/**
 * @description: 关闭弹窗
 */
const closeDialog = (): void => {
  emits('close-modal');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (__props.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              onClick: closeDialog,
              src: _imports_0,
              alt: "",
              class: "close-btn"
            }),
            _renderSlot(_ctx.$slots, "header"),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _renderSlot(_ctx.$slots, "footer")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})